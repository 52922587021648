import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import {
	demoThumbnailUrl,
	demoVideoTitle,
	demoVideoUrl,
	demoChannelUrl,
	demoChannelTitle,
} from '../utils/constants';

const VideoCard = ({
	video: {
		id: { videoId },
		snippet,
	},
}) => {
	return (
		<Card
			sx={{
				background: '#0f0f0f',
				width: { xs: '100%', md: '310px' },
				boxShadow: 'none',
			}}
		>
			<Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
				<CardMedia
					image={snippet?.thumbnails?.high?.url || demoThumbnailUrl}
					alt={snippet?.title}
					sx={{
						width: { xs: '90vw', md: '310px' },
						height: { xs: '300px', md: '170px' },
						borderRadius: '13px',
					}}
				/>
			</Link>
			<CardContent sx={{ background: '#0f0f0f', height: '106px' }}>
				<Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
					<Typography variant="subtitle1" fontWeight="bold" color="#fff">
						{snippet?.title.length > 60
							? snippet?.title.slice(0, 60) + ' ...' || demoVideoTitle.slice(0, 60)
							: snippet?.title || demoVideoTitle}
					</Typography>
				</Link>
				<Link
					to={snippet?.channelId ? `/channel/${snippet?.channelId}` : demoChannelUrl}
				>
					<Typography variant="subtitle2" fontWeight="bold" color="grey">
						{snippet?.channelTitle || demoChannelTitle}
						<CheckCircle sx={{ fontSize: 12, color: 'grey', ml: '5px' }} />
					</Typography>
				</Link>
			</CardContent>
		</Card>
	);
};

export default VideoCard;
