import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Videos, ChannelCard } from './';
import { fetchFromApi } from '../utils/fetchFromApi';

const ChannelDetail = () => {
	const [channelDetail, setChannelDetail] = useState(null);
	const [videos, setVideos] = useState([]);

	console.log(channelDetail);

	const { id } = useParams();

	useEffect(() => {
		fetchFromApi(`channels?part=snippet&id=${id}`).then((data) =>
			setChannelDetail(data?.items[0])
		);
		fetchFromApi(`search?channelId=${id}&part=snippet&order=date`).then((data) =>
			setVideos(data?.items)
		);
	}, [id]);

	return (
		<Box minHeight="95vh">
			<Box>
				<div
					style={{
						background:
							'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(121,9,9,1) 35%, rgba(255,0,0,1) 100%)',
						zIndex: 10,
						height: '200px',
					}}
				/>
				<ChannelCard channelDetail={channelDetail} marginTop="-93px" />
			</Box>
			<Box display="flex" p="2">
				<Videos videos={videos} />
			</Box>
		</Box>
	);
};

export default ChannelDetail;
